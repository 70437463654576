import { Injectable } from '@angular/core';
import { ApiService } from '@data/api/api.service';
import { map } from 'rxjs';
import { City } from '../models/city.model';
import { Promotion } from '../models/promotion.model';

const routes = {
  organizations: '/organisations',
  organization: (id: string) => `/organisations/${id}`,
  organizationGameManagers: (id: string) =>
    `/organisations/${id}/users/game-managers`,
  organizationInstanceManagers: (id: string) =>
    `/organisations/${id}/users/instance-managers`,
  organizationStaff: (id: string) => `/organisations/${id}/users/staff`,
  organizationPlayers: (id: string) => `/organisations/${id}/users/players`,
  organizationCities: (id: string) => `/organisations/${id}/cities`,
  organizationPromotions: (id: string) => `/organisations/${id}/promotions`,
};

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private apiService: ApiService) {}

  getOrganization(id: string) {
    return this.apiService.get(routes.organization(id));
  }

  getOrganizations(search?: string) {
    return this.apiService.get(
      routes.organizations + (search ? '?search=' + search : '')
    );
  }

  getGameManagers(id: string, search?: string, excludeGameId?: string) {
    return this.apiService
      .get(
        `${routes.organizationGameManagers(id)}${
          search || excludeGameId ? '?' : ''
        }${search ? 'search=' + search : ''}${
          excludeGameId
            ? (search ? '&' : '') + 'exclude-managing-game=' + excludeGameId
            : ''
        }`
      )
      .pipe(
        map((response) => {
          return response['data'];
        })
      );
  }

  getInstanceManagers(id: string, search?: string, excludeInstanceId?: string) {
    return this.apiService
      .get(
        `${routes.organizationInstanceManagers(id)}${
          search || excludeInstanceId ? '?' : ''
        }${search ? 'search=' + search : ''}${
          excludeInstanceId
            ? (search ? '&' : '') +
              'exclude-managing-instance=' +
              excludeInstanceId
            : ''
        }`
      )
      .pipe(
        map((response) => {
          return response['data'];
        })
      );
  }

  getOrganisationStaff(id: string) {
    return this.apiService.get(routes.organizationStaff(id));
  }

  getOrganisationPlayers(
    id: string,
    searchString?: string,
    cityId?: string,
    promotionId?: string
  ) {
    let queryParamsString = '';
    if (searchString || cityId || promotionId) {
      queryParamsString += '?';
    }
    if (searchString) {
      queryParamsString += 'search=' + searchString;
    }
    if (cityId) {
      queryParamsString += (searchString ? '&' : '') + 'exclude-city=' + cityId;
    }
    if (promotionId) {
      queryParamsString +=
        (searchString || cityId ? '&' : '') +
        'exclude-promotion=' +
        promotionId;
    }
    return this.apiService.get(
      `${routes.organizationPlayers(id)}${queryParamsString}`
    );
  }

  // ----------------------------------------------------------------
  // PROMOTION
  // ----------------------------------------------------------------

  getPromotion(organisationId: string, promotionId: string) {
    return this.apiService.get(
      `${routes.organizationPromotions(organisationId)}/${promotionId}`
    );
  }

  getPromotions(organisationId: string, search?: string) {
    return this.apiService.get(
      `${routes.organizationPromotions(organisationId)}${
        search ? '?search=' + search : ''
      }`
    );
  }

  getOrganisationPromotions(organisationId: string, search?: string) {
    return this.apiService.get(
      `${routes.organizationPromotions(organisationId)}${
        search ? '?search=' + search : ''
      }`
    );
  }

  createOrganisationPromotion(organisationId: string, promotion: Promotion) {
    return this.apiService.post(
      `${routes.organizationPromotions(organisationId)}`,
      promotion
    );
  }

  updateOrganisationPromotion(
    organisationId: string,
    promotionId: string,
    promotion: Promotion
  ) {
    return this.apiService.patch(
      `${routes.organizationPromotions(organisationId)}/${promotionId}`,
      promotion
    );
  }

  getPromotionUsers(organisationId: string, promotionId: string) {
    return this.apiService.get(
      `${routes.organizationPromotions(organisationId)}/${promotionId}/users`
    );
  }

  addUsersToPromotion(orgId: string, promotionId: string, users: any) {
    return this.apiService.post(
      `${routes.organizationPromotions(orgId)}/${promotionId}/users`,
      { users }
    );
  }

  removeUsersFromPromotion(orgId: string, promotionId: string, users: any) {
    return this.apiService.deleteWithBody(
      `${routes.organizationPromotions(orgId)}/${promotionId}/users`,
      { users }
    );
  }

  // ----------------------------------------------------------------
  // CITY
  // ----------------------------------------------------------------

  getCity(organisationId: string, cityId: string) {
    return this.apiService.get(
      `${routes.organizationCities(organisationId)}/${cityId}`
    );
  }

  getCities(organisationId: string, search?: string) {
    return this.apiService.get(
      `${routes.organizationCities(organisationId)}${
        search ? '?search=' + search : ''
      }`
    );
  }

  getOrganisationCities(organisationId: string, search?: string) {
    return this.apiService.get(
      `${routes.organizationCities(organisationId)}${
        search ? '?search=' + search : ''
      }`
    );
  }

  createOrganisationCity(organisationId: string, city: City) {
    return this.apiService.post(
      `${routes.organizationCities(organisationId)}`,
      city
    );
  }

  updateOrganisationCity(organisationId: string, cityId: string, city: City) {
    return this.apiService.patch(
      `${routes.organizationCities(organisationId)}/${cityId}`,
      city
    );
  }

  getCityUsers(organisationId: string, cityId: string) {
    return this.apiService.get(
      `${routes.organizationCities(organisationId)}/${cityId}/users`
    );
  }

  addUsersToCity(orgId: string, cityId: string, users: any) {
    return this.apiService.post(
      `${routes.organizationCities(orgId)}/${cityId}/users`,
      { users }
    );
  }

  removeUsersFromCity(orgId: string, cityId: string, users: any) {
    return this.apiService.deleteWithBody(
      `${routes.organizationCities(orgId)}/${cityId}/users`,
      { users }
    );
  }

  getLevelPlayedByDate(
    orgId: string,
    startDate?: string,
    endDate?: string,
    instances?: string[]
  ) {
    let call = orgId
      ? `/organisations/${orgId}/level-sessions-between-dates`
      : '/organisations/level-sessions-between-dates';
    if (startDate) {
      call += `?start-date=${startDate}`;
    }
    if (endDate) {
      call += `&end-date=${endDate}`;
    }
    if (instances) {
      for (let i = 0; i < instances.length; i++) {
        call += `&instance=${instances[i]}`;
      }
    }
    return this.apiService.get(call);
  }

  createUsersFromCsv(orgId: string, file: any) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.apiService.postImage(
      `/organisations/${orgId}/users/from-csv`,
      formData
    );
  }

  deleteOrganization(orgId: string) {
    return this.apiService.delete(`/organisations/${orgId}`);
  }

  createOrganization(organization: any) {
    return this.apiService.post(`/organisations`, organization);
  }

  updateOrganization(orgId: string, organization: any) {
    return this.apiService.patch(`/organisations/${orgId}`, organization);
  }

  uploadOrganizationLogo(orgId: string, file: any) {
    return this.apiService.postImage(`/organisations/${orgId}/logo`, file);
  }
}
